import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { createApolloClient } from '@nhost/apollo';
import { useLazyQuery as originalUseLazyQuery } from '@vue/apollo-composable';

type UseQueryArgs<
  TResult,
  TVariables extends Record<string, unknown>
> = Parameters<typeof originalUseLazyQuery<TResult, TVariables>>;

type DocumentParameters<
  TResult,
  TVariables extends Record<string, unknown>
> = UseQueryArgs<TResult, TVariables>[0];

type VariablesParameters<TVariables extends Record<string, unknown>> =
  UseQueryArgs<unknown, TVariables>[1];

type OptionsParameters<
  TResult,
  TVariables extends Record<string, unknown>
> = UseQueryArgs<TResult, TVariables>[2];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useClientQuery = <
  TResult = any,
  TVariables extends Record<string, unknown> = any
>(
  document: DocumentParameters<TResult, TVariables>,
  variables: VariablesParameters<TVariables>,
  options: OptionsParameters<TResult, TVariables> = {}
) => {
  const queryResult = originalUseLazyQuery<TResult, TVariables>(
    document,
    variables,
    options
  );
  watch(
    queryResult.result,
    () => {
      if (process.client && queryResult.result.value === undefined) {
        queryResult.load();
      }
    },
    { immediate: true }
  );
  return queryResult;
};

export const useLazyQuery = originalUseLazyQuery;

let wpClient: ApolloClient<NormalizedCacheObject> | null = null;

export const useWpClient = () => {
  if (!wpClient) {
    const {
      public: { wpUrl }
    } = useRuntimeConfig();

    wpClient = createApolloClient({
      graphqlUrl: `${wpUrl}/graphql`,
      cache: new InMemoryCache(),
      link: new HttpLink({
        uri: `${wpUrl}/graphql`,
        fetchOptions: {
          method: 'GET'
        }
      })
    });
  }
  return wpClient;
};

let wpBlogClient: ApolloClient<NormalizedCacheObject> | null = null;

export const useWpBlogClient = () => {
  if (!wpBlogClient) {
    const {
      public: { wpBlogUrl }
    } = useRuntimeConfig();

    wpBlogClient = createApolloClient({
      graphqlUrl: `${wpBlogUrl}/graphql`,
      cache: new InMemoryCache(),
      link: new HttpLink({
        uri: `${wpBlogUrl}/graphql`,
        fetchOptions: {
          method: 'GET'
        }
      })
    });
  }
  return wpBlogClient;
};

let analyticsClient: ApolloClient<NormalizedCacheObject> | null = null;

export const useAnalyticsClient = () => {
  if (!analyticsClient) {
    const {
      public: { analyticsUrl, analyticsToken }
    } = useRuntimeConfig();

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: analyticsToken || ''
        }
      };
    });

    // includes the analytics token in the headers

    analyticsClient = createApolloClient({
      graphqlUrl: `${analyticsUrl}/graphql`,
      cache: new InMemoryCache(),
      headers: {
        authorization: analyticsToken || ''
      },
      link: authLink.concat(
        new HttpLink({
          uri: `${analyticsUrl}/graphql`,
          fetchOptions: {
            method: 'GET',
            headers: {
              authorization: analyticsToken || ''
            }
          }
        })
      )
    });
  }
  return analyticsClient;
};
